.restrict{
    // 头部样式
    .liveChat{
        display: flex;
        align-items: center;
        color: #FFF;
        cursor: pointer;
        img{
            width: 1.5rem;
            margin-right: 10px;
        }
    }
    .restrict-section-web{
        height: 92.2vh;
        display: flex;
        .IP-bg{
            width: 100%;
            object-fit: cover;
            max-width: 100%;
            height: auto;
            background: url('/static/images/restrict/IP-bg.jpg') no-repeat 0 0;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            .restrict-section-web-flex{
                display: flex;
                justify-content: space-between;
                .left{
                    width: 60%;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .right{
                    color: #FFF;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 40%;
                    padding: 2rem;
                    .p1{
                        font-size: 3rem;
                        line-height: 1;
                        margin-bottom: 16px;
                    }
                    .p2,.p3,.p4{
                        line-height: 2;
                        font-size: 16px;
                    }
                    .Btn{
                        margin-top: 3rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        color: #FFF;
                        .bt1 ,.bt2{
                            width: 90%;
                            line-height: 3.54rem;
                            background-color: #00B324;
                            border: none;
                            color: #FFF;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                        .bt2{
                            background-color: transparent;
                            color: #FFF;
                            border-radius: 5px;
                            border: 1px solid #00B324;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}


// mobile

@media (max-width:1023px){
    .restrict{
        background-color: rgb(23 23 23);
        .mobile{
            .liveChat{
                margin-left: 20px;
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
        }
        .restrict-section-web {
              .IP-bg{
                align-items: flex-start;
                .restrict-section-web-flex{
                    flex-direction: column;
                    align-items: center;
                    padding-top: 1rem;
                    .left,.right{
                        width: auto ;
                        padding: 0 ;
                        .p2 ,.p3 ,.p4{
                            font-size: 14px ;
                        }
                        .Btn{
                            margin-top: 10px;
                            .bt1,.bt2{
                                width: 100%;
                            }
                        }
                    }
                }
              }
        }
        
    }       
}