
// M1-001 Page 样式
.mobile-qrcode{
    display: none;
}
.hayhar-page.m1-001, .hayhar-page.m1-002,.hayhar-page.m1-003,.hayhar-page.m1-004,.hayhar-page.m1-005{
    .ant-carousel{
        height: 400px;    
        .img_wrap{
            height: 400px;
        }
    }
    .copyright-pay-safe{
        display: none;
    }
    .montenegro{
        display: none;
    }
}
.hayhar-middle{
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
}
.register-box{
    width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 10px;
    .box-head{
        padding: .5rem 1.25rem;
        border-radius: .25rem .25rem 0 0 ;
        position: relative;
        color: rgb(26,255,0,);
        background: linear-gradient(-71deg,#212121 300px,#111 0) #000;
        border:1px solid rgb(13,127,0);
        border-bottom: none;

        .icon{
            position:absolute;
            top: 1px;
            left: 0;
        }
        .icon2{
            width: 45px;
            height: 45px;
            position:absolute;
            top: 8px;
            left: 8px;
        }
    }
    .tips{
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .registration-content , .qrcode-content{
        border: 1px solid #ccc;
        border-top: 0;
        padding: 0 24px 24px;
        border-radius: 0 0 .25rem .25rem;
        display: flex;
        flex-direction: column;
        background: rgb(23, 23, 23);
        box-sizing: border-box;
        border: 1px solid rgb(13,127,0);
        border-top: none;
        min-height: 24rem;
        .phone-number,.flexInput{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            label{
                color: rgb(156,163,175);
                width: 29.333%;
            }
        }
        input{
            border: 1px solid #6b7280;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            flex: 1 1 0%;
            background-color: transparent;
            color: #FFF;
            &:focus{
                border-color: #2563eb ;
            }
            &.phoneNumber{
                width: 69.97%;
            }
            &.ant-input-disabled.phone{
                width: 24.99%;
            }
        }
    }


    .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
        font-size: 12px;
        color: #ff0000;
        min-height: 1.5rem;
        margin-top: 0.5rem;
        margin-left: 29.33%;
        display: inline-table;
        filter: contrast(1);
        font-weight: bold;
    }

    .qrcode-content{
        align-items: flex-start;
        flex-direction:row;
        justify-content: initial;
        padding-top: 24px;
        .Down,.down-tips{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
        .Down{
            flex-direction: column;
        }
        .down-tips{
            p{
                width: 50%;
                text-align: center;
                font-size: 13px;
                color: grey;
                margin-top: 10px;
            }
            justify-content: center;
            align-items: center;
        }
        .btnDownload{
            display: flex;
            color: #333;
            cursor: pointer;
            border-radius: 4px;
            align-items: center;
            color: #FFF;
            margin-top: 10px;
            img{
                width: 55%;
            }
        }


        .installation{
            list-style: none;
            font-size: 14px;
            color: #FFF;
            margin: 0px 0px 0  0px;
            li{
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
    }    
}

.btn-download-pc{
    color: #fff !important;
    background-color: rgb(13,127,0);
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    padding: 6px 40px;
    width: fit-content;
    margin: 10px auto;
    cursor: pointer;
    border: none;
}

.register-submit{
    // margin: 0 auto;
}

.ant-modal-centered{
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;
    left:0;
    z-index: 1;
    overflow: auto;
    outline: 0;
    background-color: rgba(34, 34, 34, .45);
}

.ant-modal-centered .ant-modal{
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}


//滑动验证
.fpcaptch-modal {
    z-index: 2002;
    width: 332px !important;
    .ant-modal-body .ant-spin {
        display: block;
        opacity: 0.6;
        position: absolute;
        border-radius: 10px;
        left:41%;
        top: 50%;
        bottom: 0;
        right: 0;
        // background: #00000036;
        z-index: 11;
    }
    .ant-modal-body .ant-spin.hide,
    .user-center-loading.ant-spin.hide {
        display: none !important;
    }
    .ant-modal-content {
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .08), 0 2px 8px 2px rgba(0, 0, 0, .05);
        pointer-events: auto;
    
        position: fixed;
        width: 320px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the modal horizontally and vertically */
    }
    
    /* Add a media query for mobile devices */
    @media (max-width: 768px) {
        .ant-modal-content {
            width: 90%; /* Make the modal width responsive */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Keep the modal centered */
        }
    }
    
    @media (max-width: 480px) {
        .ant-modal-content {
            width: 90%; /* Full width on smaller mobile screens */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Center the modal */
        }
    }
    
    
    .ant-modal-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        padding: 0;
        color: rgba(34, 34, 34, .45);
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color .3s;
    }
    .ant-modal-close-x{
        display: block;
        width: 56px;
        height: 56px;
        font-size: 18px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
    }
    .ant-modal-body {
        padding: 16px 16px 10px 16px;
    }

    .fpcontent {
        .fpSection {
            width: 100%;
            height: 150px;
            .moveElement {
                width: 60px;
                height: 60px;
                position: relative;
                cursor: pointer;
            }

            .info {
                width: 85%;
                position: absolute;
                background-color: #000;
                opacity: 0.7;
                left: 5%;
                top: 20%;
                color: #fff;
                height: 150px;

                img {
                    margin: 10px 0 0 90%;
                    width: 18px;
                    height: auto;
                    cursor: pointer;
                }

                p {
                    padding: 15px;
                }
            }
        }
        .verifySuccess {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: #10a0004a;
            color: #42d200;

            img {
                width: 18px;
                height: auto;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .verifyFail {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: rgba(255, 16, 0, 0.20);;
            color: #F11818;
            padding-left: 0px;
            img {
                width: 18px;
                height: auto;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .fpFooter {
            margin: 10px 0 0;

            img {
                cursor: pointer;

                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }
}