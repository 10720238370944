ul{
	list-style: none;
}

// 公共样式
.hayhar-page,.m2-002,.m2-003,.m2-004,.m2-005,.m2-006{
	.text-top{
		display: none !important;
	}
	.ant-carousel{
		.slick-list{
			.slick-track{
				display: flex;
			}
			.img_wrap{
				img{
					object-fit:initial !important;
				}
			}
		}
		
	}
	
}

// WEB 样式
.hayhar-page,.m2-001,.m2-002,.m2-003,.m2-004,.m2-005,.m2-006{
	.ant-carousel{
		height: 730px;
	}
	position: relative;
	overflow: hidden;
	background-color: #000;
	.img_wrap{
		img{
			object-fit:cover;
			height: 100%;
			width: 100%;
			vertical-align: middle;
		}
		height: 730px;
	}
	.mobile{
		display:none;
		
		&.top{
			display: none;
		}
		
	}
	
	.top.web{
		input{
			height: 2rem;
		}
	}
	input{
		height: 2.65rem;
		border-radius:.25rem;
		padding: .25rem .3rem;
		box-sizing: border-box;
		outline-style:none;
		outline-width: 0px;
		border:2px solid transparent;
		text-shadow: none;
		-webkit-appearance:none;
		-webkit-user-select:text;
		outline-color: transparent;
		box-shadow: none;
		text-overflow:ellipsis;
	}
	.top{
		padding: 1em;
		background-color: rgb(23,23,23);
		display: flex;
		justify-content: space-between;
		align-items: center;
		.qrp-header-logo{
			width: 8rem;
		}
		
		// 输入框
		.web-input-box{
			position: relative;
			display: flex;
			align-items: center;
			.web-input{
				
				&:focus{
					box-shadow: 0 0, #93c5fd;
					border-color:#93c5fd;
					
				}
			}
			.web-input+.web-input{
				margin-left: .5rem;
				width: 15rem;
				padding-right: 5rem;
				// margin-right: 5rem;
			}
			.web-label{
				position: absolute;
				top: .5rem;
				right:9.6rem;
				color: inherit;
				text-decoration:inherit;
				font-size: .75rem;
				line-height: 1rem;
				cursor: pointer;
				padding-left: .3rem;
				border-left: 1px solid rgba(229,231,235,1);
				color: rgba(56,189,248,1);
				&:hover{
					color: rgba(56,189,248,1);
				}
			}
			
			// 登录按钮
			.web-login{
				padding: 0.25rem 1rem;
				margin-left: .3rem;
				background-color: #FFF;
				border:none;
				height: 2rem;
				border-radius: .25rem;
				border: 1px solid transparent;
				&:hover{
					border-color: #FFF;
					background-color: rgb(56, 189, 248);
					color: #FFF;
				}
			}
			.country-icon{
				margin-left: .6rem;
				border-left: 1px solid #FFF;
				padding-left: .6rem;
			}
			
		}
	}
	
	
	.ant-input{
		color: #000;
	}

	.error-hit{
		display: flex;
		margin-bottom: 1rem;
		p{
			color: #ff0000;
			width: 50%;
			text-align: left;
		}
	}

	// 中间内容
	.central-web{
		// background: url(/static/images/hayhar/QRP-NUFC-Desktop.jpg) no-repeat center ;
		// background-size:100%;
		overflow: hidden;
		// position: relative;
		.text-top{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: .8rem auto;
			position: absolute;
			top: 4.55rem;
			left: 30%;
			h2{
				font-size: 3rem;
			}
			p{
				font-size: 2rem;
			}
		}
		.central-box{
			// position: absolute;
			border-radius: .5rem;
			overflow: hidden;
			background-color: #FFF;
			margin: 5% 0 0 56%;
			width: 27rem;
			position: absolute;
			top: 12rem;
			.tips{
				color:#9ca3af ;
				margin: .75rem auto 0;
				text-align: center;
				
			}
			.heading{
				padding:1rem 0;
				background-color: rgb(56, 189, 248);
				text-align: center;
				color: #FFF;
			}
			.form-modal{
				padding: 1.25rem;
				padding-bottom: 0;
				input{
					border: 1px solid rgb(209,213,219);
					-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(209,213,219 .6);
					box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .075) , 0px 0px 8px rgba(209,213,219 .6);
					
					&:focus{
						border:2px solid rgb(56, 189, 248);
					}
				}
				.namePass{
					display: flex;	
					justify-content: space-between;
					margin-bottom: 1rem;
					input{
						width: 49%;
					}
				}
				.error-hit{
					display: flex;
					margin-bottom: 1rem;
					p{
						color: #ff0000;
						width: 50%;
						text-align: left;
					}
				}
				.email-input{
					display: flex;
					margin-bottom: 1rem;
					input{
						width: 100%;
					}
					
				}
				.phone-number{
					display: flex;
					justify-content: space-between;
					input.phone{
						width: 20%;
						color: #9ca3af;
						cursor: no-drop;
					}
					input.phoneNumber{
						width: 78%;
					}
				}
			}
			.submit{
				margin: 1rem auto;
				padding: 8px 16px;
				display: flex;
				justify-content: center;
				background-color: #0ea5e9;
				border: none;
				border-radius: .25rem;
				color: #FFF;
				font-size: .95rem;
				&:hover{
					background-color: rgb(56,189,248);
				}
			}
		}
	}
	
	
	// 底部内容
	
	.footer-web{

		.footer-content-web{
			display: flex;
			max-width: 1200px;
			padding:1.5rem 2.5rem;
			margin: 0 auto;
			border-bottom: 1px solid #111;
			img{
				max-width: 100%;
				height: auto;
				margin-top:calc(.5rem*(1 - 0))
			}
			.sponsorship{
				img{
					max-width: 300px;
				}
			}
			.partner{
				margin: 0  calc(2rem*(1 - 0));
			}
			.sponsorship,.partner,.paymentplatform{
				flex: 0 1 auto;
				p{
					color: #FFF;
	
					font-size: .875rem;
					line-height: 1.25rem;
				}
			}	
		}

		.footer-end_list{
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 1280px;
			padding: 1.5rem;
			font-size: .875rem;
			margin: 5rem auto 0;
			flex-direction: column;
			.footer-end_list-box{
				display: flex;
				justify-content: center;
				align-items: center;
				.item{
					padding: 0 0.5rem;
					cursor: pointer;
					font-size: small;
					color: #999;
					text-decoration: none;
					margin-bottom: 0.45rem;
					&+.item{
						border-left: 1px solid #303030;
					}
				}
				.item:hover {
					color: #ccc;
				}
			}
			@media screen and (max-width:600px) {
				.footer-end_list-box-005{
					display: none;
				}
			}
			@media screen and (min-width:600px) {
			.footer-end_list-box-005{
				display: flex;
				justify-content: center;
				align-items: center;
				.item{
					padding: 0 0.5rem;
					cursor: pointer;
					font-size: small;
					color: #999;
					text-decoration: none;
					margin-bottom: 0.45rem;
					&+.item{
						border-left: 1px solid #303030;
					}
				}
				.item:hover {
					color: #ccc;
				}
			}
		}
			span{
				color: rgb(75,85,99);
				font-size: .75rem;
				line-height: 1rem;
				display: inline-block;
				margin-top: .75rem;
			}
			

		}

	}
	
	
	
	// 社交按钮
	.social-web{
		position: fixed;
		transform: translate(50%, -50%);
		top: 48%;
		right: -1%;
		.social-item{
			text-decoration: inherit;
			cursor: pointer;
			display: flex;
			align-items: center;
			background-color: rgb(2,132,199);
			border-top-left-radius: 9999px;
			border-bottom-left-radius: 9999px;
			margin: .5rem 0;
			box-shadow: 0 0 #000 , 0 0 #0000 , 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
			padding:1rem;
			white-space:nowrap;
			color: #FFF;
			width: 100%;
			&:hover{
				transform: translate(-90px,0);
				transition-duration:.3s;
			}
			img{
				height: 2.5rem;
				margin-right: .75rem;
			}
		}
	}
	
}

@import url('./mobile.less');