// 弹窗提示
.modal{
    position: fixed;
	width: 100%;
	z-index: 1999;
	text-align: center;
    height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	left: 0;
	top: 0;
	-webkit-transform: translateZ(1px);
	transform: translateZ(1px);
	background-color: rgba(0, 0, 0, 0.4);

    .modal-box{
        position: fixed;
        max-width: 50%;
        width: auto;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        border-radius: .5rem;
        min-width: 400px;
        --tw-bg-opacity: 1;
        background-color: rgba(17,24,39,var(--tw-bg-opacity));
        padding: 1.25rem;
        overflow: hidden;
        .header{
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700;
            color: rgba(0,179,36);
        }
        .center{
            padding: 16px 0;
            color: #FFF;
            font-size: .75rem;
            line-height: 1rem;
        }


        .fill-current {
            display: block;
            vertical-align: middle;
            fill: currentColor;
            --tw-text-opacity: 1;
            color: rgba(209,213,219,var(--tw-text-opacity));
        }

        .btn{
            --tw-text-opacity: 1;
            color: rgba(209,213,219,var(--tw-text-opacity));
            background: inherit;
            border: navajowhite;
            display: flex;
            justify-content: flex-end;
            width: 24rem;
   
        }
    }

}

 